/* eslint-disable max-len */
import axios from "axios";

const API_URL = "https://gestionale-gls-back-1ef40772ddc2.herokuapp.com";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: localStorage.getItem("glsToken"),
    "Content-Type": "application/json",
  },
});

export default api;
