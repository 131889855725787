import React from "react";
import Lottie from "lottie-react";
import success from "../../images/lottie/success.json";

export default function ModalAlert({ setIsModalAlertOpen, serverResponse }) {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="modal"
      onClick={() => {
        setIsModalAlertOpen(false);
        window.location.reload();
      }}
    >
      <div className="innerModal" onClick={stopPropagation}>
        <>
          {serverResponse == 0 ? (
            <>
              <div className="headerAlertModal">
                <h1>Ops... qualcosa è andato storto</h1>
                <p>
                  Prova a contattare l'assistenza per risolvere il problema il
                  prima possibile
                </p>
              </div>
              <div
                className="annulla"
                onClick={() => setIsModalAlertOpen(false)}
              >
                Va bene
              </div>
            </>
          ) : (
            <div className="successAlertModal">
              <div className="headerSuccessAlertModal">
                <Lottie
                  animationData={success}
                  loop={false}
                  width={60}
                ></Lottie>

                <div className="textAlertModal">
                  <h1>Perfetto!</h1>
                  <p>Il tuo report è stato caricato con successo</p>
                </div>
              </div>
              <div
                className="annulla"
                onClick={() => {
                  window.location.reload();
                  setIsModalAlertOpen(false);
                }}
              >
                Okay!
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
