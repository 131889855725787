import React, { useState } from "react";
import "./card.css";
import moment from "moment-timezone";
import "moment/locale/it";
import ticket from "../../images/svg/ticket.svg";
import sinistro from "../../images/svg/sinistro.svg";
import ModalStepper from "../modal/ModalStepper";
import ModalEdit from "../modal/ModalEdit";
import ModalAllegati from "../modal/ModalAllegati";
import api from "../../core/api";

import edit from "../../images/svg/edit.svg";

export default function Card({
  autista,
  targa,
  timestamp,
  modification_timestamp,
  stato,
  type,
  commento,
  dataScadenza,
  numeroPratica,
  id,
  isMain,
  setLoader,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const [newStato, setNewStato] = useState();
  const [isModalStepperOpen, setIsModalStepperOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalAllegatiOpen, setIsModalAllegatiOpen] = useState(0);

  const today = moment();
  const expirationDate = moment(dataScadenza);
  const daysUntilExpiration = expirationDate.diff(today, "days");

  // Determine if the date is within 5 days
  const isCloseToExpiration =
    daysUntilExpiration <= 5 && daysUntilExpiration >= 0;

  const handleCloseReport = () => {
    api
      .post("/closeReport", { id })
      .then((res) => {
        setLoader(true);
        window.location.reload();
      })
      .catch((err) => {
        alert(err);
      });
  };

  // const handleIsSelected = () => {
  //   setIsSelected(!isSelected);
  //   isSelected === true ? (
  //     api.post("/getCommenti", { id }).then((res) => {
  //       setCommento(
  //         res.data.data.map((res) => (
  //           <span>
  //             {res.commento} -{res.nome} <br />
  //           </span>
  //         ))
  //       );
  //     })
  //   ) : (
  //     <></>
  //   );
  // };
  // // This function converts newline characters to <br/> tags
  // function formatComment(commento) {
  //   return commento.split("\\").map((line, index) => (
  //     <span key={index}>
  //       {line}
  //       <br />
  //     </span>
  //   ));
  // }
  return (
    <>
      <div className="card" onClick={() => setIsSelected(!isSelected)}>
        <div className="headline">
          <div className="headlineLeft">
            <div className="cardTitle">{autista}</div>
            <div className="cardTitle">{targa}</div>
            <div className="cardTitle">
              {numeroPratica && <>#{numeroPratica}</>}
            </div>
            <div style={{ color: isCloseToExpiration ? "red" : "inherit" }}>
              Scadenza:{" "}
              {dataScadenza
                ? moment(dataScadenza)
                    .utcOffset("+04:00")
                    .locale("it")
                    .format("dddd, D MMMM YYYY")
                : "Non specificata"}
            </div>
          </div>
          <div className="headlineRight">
            <div className="stato">
              {stato == 2 ? "Completo" : stato == 1 ? "In corso" : "Nuovo"}
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  marginTop: "3px",
                  borderRadius: "50px",
                  backgroundColor:
                    stato == 2 ? "blue" : stato == 1 ? "red" : "orange",
                }}
              ></div>
            </div>
            <div className="type">
              {type == 0 ? (
                <div className="multa">
                  <img src={ticket} alt="Multa" width={"14px"} />
                  Multa
                </div>
              ) : type == 1 ? (
                <div className="sinistro">
                  <img src={sinistro} alt="Sinistro" width={"14px"} />
                  Sinistro
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mod" onClick={() => setIsModalEditOpen(true)}>
              <img src={edit} alt="" /> Modifica
            </div>
          </div>
        </div>
        {isMain || isSelected ? (
          <div className="midline">
            <div className="commentoShow">
              <br />
              {commento} <br /> <br />
              <p onClick={() => setIsModalAllegatiOpen(id)}>
                <u>Mostra allegati</u>
              </p>
              {modification_timestamp ? "Creato il " : null}
              {moment(timestamp)
                .utcOffset("+04:00")
                .locale("it")
                .format("dddd, D MMMM YYYY, H:mm")}
              {modification_timestamp ? (
                <>
                  <br /> Modificato il{" "}
                  {moment(modification_timestamp)
                    .utcOffset("+02:00")
                    .locale("it")
                    .format("dddd, D MMMM YYYY, H:mm")}
                </>
              ) : null}
            </div>
            {stato == 2 ? (
              <div
                onClick={() => setIsModalStepperOpen(true)}
                className={"nextStepButton"}
              >
                Riapri pratica
              </div>
            ) : (
              <div className="buttonGrid">
                <div
                  onClick={() => setIsModalStepperOpen(true)}
                  className={"nextStepButton"}
                >
                  Procedi al prossimo step
                </div>
                {stato == 2 ? null : (
                  <div className="nextStepButton" onClick={handleCloseReport}>
                    Chiudi
                  </div>
                )}
              </div>
            )}
          </div>
        ) : null}
      </div>
      {isModalAllegatiOpen !== 0 && (
        <ModalAllegati
          id={isModalAllegatiOpen}
          setIsModalAllegatiOpen={setIsModalAllegatiOpen}
        />
      )}
      {isModalEditOpen && (
        <ModalEdit
          stato={stato}
          type={type}
          id={id}
          setLoader={setLoader}
          autista={autista}
          targa={targa}
          setIsModalEditOpen={setIsModalEditOpen}
          commento={commento}
          dataScadenza={dataScadenza}
          numeroPratica={numeroPratica}
        />
      )}
      {isModalStepperOpen && (
        <ModalStepper
          stato={stato}
          id={id}
          setLoader={setLoader}
          autista={autista}
          targa={targa}
          commento={commento}
          setIsModalStepperOpen={setIsModalStepperOpen}
        />
      )}
    </>
  );
}
