import React from "react";
import "./filePreview.css";
import landscape from "../../images/svg/landscape.svg";

export default function FilePreview({ fileStored }) {
  const isPDF = (name) => {
    return name.endsWith(".pdf"); // or any other logic to determine if the file is a PDF
  };

  return (
    <div className="fileStored">
      {isPDF(fileStored.name) ? (
        <img
          src={landscape}
          alt="File Preview"
          style={{
            margin: "0 20px",
            width: "32px",
            height: "32px",
            objectFit: "cover",
          }}
        />
      ) : (
        <img
          src={fileStored.url}
          alt="File Preview"
          style={{ width: "60px", height: "60px", objectFit: "cover" }}
        />
      )}

      <p>{fileStored.name}</p>
    </div>
  );
}
