import React, { useState, useEffect } from "react";
import "./navbar.css";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();

  const [path, setPath] = useState("");
  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  return (
    <div className="navbar">
      <div
        style={{
          fontWeight: path === "/" ? "bold" : "normal",
        }}
        onClick={() => navigate("/")}
      >
        <div>Report</div>
        <div
          style={{
            height: "3px",
            width: "100%",
            borderRadius: "10px",
            backgroundColor: path === "/" ? "#000" : "transparent",
          }}
        ></div>
      </div>
      <div
        style={{
          fontWeight:
            path === "/Cronologia" || path === "/cronologia"
              ? "bold"
              : "normal",
        }}
        onClick={() => navigate("/Cronologia")}
      >
        <div>Cronologia</div>{" "}
        <div
          style={{
            height: "3px",
            width: "100%",
            borderRadius: "10px",
            backgroundColor:
              path === "/Cronologia" || path === "/cronologia"
                ? "#000"
                : "transparent",
          }}
        ></div>
      </div>
    </div>
  );
}
