import React, { useState } from "react";
import "./modalStepper.css";
import api from "../../core/api";
import close from "../../images/svg/close.svg";

export default function ModalEdit({
  stato, // Status of the report
  type, // Type of the report
  id,
  setLoader,
  autista,
  targa,
  setIsModalEditOpen,
  commento,
  dataScadenza,
  numeroPratica,
}) {
  const [newType, setNewType] = useState(type);
  const [newDataScadenza, setNewDataScadenza] = useState(dataScadenza);
  const [newNumeroPratica, setNewNumeroPratica] = useState(numeroPratica);
  const [newAutista, setNewAutista] = useState(autista);
  const [newTarga, setNewTarga] = useState(targa);
  const [newCommento, setNewCommento] = useState("");

  const handleModFields = () => {
    api
      .put("/modReport", {
        id,
        type: newType,
        autista: newAutista,
        targa: newTarga,
        commento: newCommento,
        dataScadenza: newDataScadenza,
        numeroPratica: newNumeroPratica,
      })
      .then((res) => {
        setLoader(true);
        window.location.reload();
      })
      .catch((err) => {
        setLoader(true);
        alert("Errore nel caricamento del report");
      });
  };

  const defaultDate = new Date(dataScadenza).toISOString().split("T")[0];

  // function formatComment(commento) {
  //   return commento.split("\\").map((line, index) => (
  //     <span key={index}>
  //       {line}
  //       <br />
  //     </span>
  //   ));
  // }

  return (
    <div className="modal" onClick={() => setIsModalEditOpen(false)}>
      <div className="innerModal" onClick={(e) => e.stopPropagation()}>
        <div className="exit" onClick={() => setIsModalEditOpen(false)}>
          <img src={close} alt="chiudi" />
        </div>

        <div className="dropdownHeader">Modifica report:</div>
        <div className="inputButtons">
          <div className="inputButton">
            <input
              type="date"
              name="dataScadenza"
              id="dataScadenza"
              placeholder="Data di Scadenza"
              defaultValue={defaultDate}
              onChange={(e) => setNewDataScadenza(e.target.value)}
            />
          </div>
          <div className="inputButton">
            <input
              type="text"
              name="numeroPratica"
              id="numeroPratica"
              placeholder="Numero di Pratica"
              defaultValue={numeroPratica}
              onChange={(e) => setNewNumeroPratica(e.target.value)}
            />
          </div>
        </div>
        <div className="buttonsType">
          <div
            style={{
              borderRadius: "50px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: "20px 50px",
              flexWrap: "wrap",
              backgroundColor: newType == 0 ? "#ce8c94" : "#FFFFFF",
              cursor: "pointer",
            }}
            onClick={() => {
              setNewType(0);
            }}
          >
            Multa
          </div>
          <div
            style={{
              borderRadius: "50px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: "20px 50px",
              flexWrap: "wrap",
              backgroundColor: newType == 1 ? "#86b1bb" : "#FFFFFF",
              cursor: "pointer",
            }}
            onClick={() => {
              setNewType(1);
            }}
          >
            Sinistro
          </div>
        </div>
        <div className="inputButtons">
          <div className="inputButton">
            <input
              type="text"
              name="autista"
              id="autista"
              placeholder="Autista"
              defaultValue={autista}
              onChange={(e) => setNewAutista(e.target.value)}
            />
          </div>
          <div className="inputButton">
            <input
              type="text"
              name="targa"
              id="targa"
              placeholder="Targa"
              defaultValue={targa}
              onChange={(e) => setNewTarga(e.target.value)}
            />
          </div>
        </div>
        {/* <div className="commento">
          <div>{formatComment(commento)}</div>
          <br />
          <br />
          <textarea
            type="text"
            className="commentoInput"
            onChange={(e) => setNewCommento(e.target.value)}
            placeholder="Scrivi qui..."
            value={newCommento}
          ></textarea>
        </div> */}
        <div className="annulla" onClick={() => setIsModalEditOpen(false)}>
          Annulla
        </div>
        <div className="confirm" onClick={() => handleModFields()}>
          Conferma
        </div>
      </div>
    </div>
  );
}
