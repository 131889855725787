import React, { useState, useEffect } from "react";
import Card from "../../components/card/Card";
import Navbar from "../../components/navbar/Navbar";
import api from "../../core/api";
import "./cronologia.css";
import Loader from "../../components/loader/Loader";

export default function Cronologia() {
  const [loader, setLoader] = useState(true);
  const [commenti, setCommenti] = useState([]);
  const [newStato, setNewStato] = useState(-1);
  const [cards, setCards] = useState([
    {
      autista: "",
      targa: "",
      modification_timestamp: "",
      data: "",
      stato: -1,
      type: "",
      dataScadenza: "",
      numeroPratica: "",
      isMain: false,
      id: 0,
    },
  ]);

  useEffect(() => {
    setLoader(true);
    api.get("/getCronologia").then((res) => {
      if (res.status === 200) {
        setLoader(false);
        setCards(res.data.data);
      } else {
        alert("Errore nel caricamento della cronologia");
      }
    });
    api.get("/getCommenti").then((res) => {
      if (res.status === 200) {
        setCommenti(res.data.data);
      } else {
        alert("Errore nel caricamento degli stati");
      }
    });
  }, []);
  const downloadFile = async () => {
    try {
      const response = await api.get("/api/download", {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "DatabaseGLS.accdb");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <div className="cronologia">
      <Navbar></Navbar>
      <div className="accessDiv">
        <div
          // href="file:///C:/Users/stora/Desktop/DatabaseGLS.accdb"
          // download="DatabaseGLS.accdb"
          onClick={downloadFile}
          className="access"
        >
          Scarica database
        </div>
      </div>
      {cards.map((card) => (
        <Card
          autista={card.autista}
          targa={card.targa}
          timestamp={card.timestamp}
          modification_timestamp={card.modification_timestamp}
          stato={card.stato}
          type={card.type}
          commento={commenti
            .filter((commento) => commento.idRelativo === card.id)
            .map((commento) => (
              <span>
                {commento.commento} -{commento.nome} <br />
              </span>
            ))}
          dataScadenza={card.dataScadenza}
          numeroPratica={card.numeroPratica}
          isMain={card.isMain}
          id={card.id}
          setLoader={setLoader}
        ></Card>
      ))}
      {loader && <Loader />}
    </div>
  );
}
