import React, { useState, useEffect } from "react";
// import Card from "../../components/card/Card";
import Navbar from "../../components/navbar/Navbar";
import Select from "react-select";
import api from "../../core/api";
import Loader from "../../components/loader/Loader";
import "./Report.css";
import DropZoneModal from "../../components/modal/DropZoneModal";
import FilePreview from "../../components/filePreview/FilePreview";
import ModalAlert from "../../components/modal/ModalAlert";

export default function Report() {
  const [loader, setLoader] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [autisti, setAutisti] = useState([{ value: "", label: "" }]);
  const [targhe, setTarghe] = useState([{ value: "", label: "" }]);
  const [type, setType] = useState(-1);
  const [report, setReport] = useState({
    targa: "",
    autista: "",
    commento: "",
    type: "",
    nome: "",
  });
  const [fileStored, setFileStored] = useState();

  const [serverResponse, setServerResponse] = useState(-1);
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false);
  const [isModalCaricaAllegatoOpen, setIsModalCaricaAllegatoOpen] =
    useState(false);

  //al value dovrei mettere l'id così da controllare latarga consigliata
  useEffect(() => {
    setLoader(true);
    api.get("/getData").then((res) => {
      if (res.status == 200) {
        setLoader(false);
        setAutisti(
          res?.data.data.map((name) => ({ value: name.name, label: name.name }))
        );
        setTarghe(
          res?.data.data.map((targa) => ({
            value: targa.targaConsigliata,
            label: targa.targaConsigliata,
          }))
        );
      } else {
        alert("Errore nel caricamento dei dati");
      }
    });
    setReport((prevReport) => ({
      ...prevReport,
      nome: localStorage.getItem("glsUtente"),
    }));
  }, []);

  useEffect(() => {
    setReport((prevReport) => ({
      ...prevReport,
      fileStored: fileStored,
    }));
  }, [fileStored]);

  const handleChange = (property, selectedOption) => {
    // Update the 'autista' property in the 'report' state
    setReport((prevReport) => ({
      ...prevReport,
      [property]: selectedOption.value ? selectedOption.value : selectedOption,
    }));
    console.log(report);
  };

  const handleReport = () => {
    setLoader(true);
    setButtonDisabled(true);
    api
      .post("/addReport", report)
      .then((res) => {
        setIsModalAlertOpen(true);
        setServerResponse(1);
        setLoader(false);
      })
      .catch((err) => {
        setIsModalAlertOpen(true);
        setServerResponse(0);
        setLoader(false);
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  return (
    <div className="report">
      <Navbar></Navbar>
      {isModalAlertOpen && (
        <ModalAlert
          serverResponse={serverResponse}
          setIsModalAlertOpen={() => setIsModalAlertOpen()}
        />
      )}
      {isModalCaricaAllegatoOpen && (
        <DropZoneModal
          setIsModalCaricaAllegatoOpen={() => setIsModalCaricaAllegatoOpen()}
          setFileStored={setFileStored}
        ></DropZoneModal>
      )}
      <div className="reportCard">
        <div className="title">Inizia un nuovo Report</div>
        <div className="dropdownHeader">Autista:</div>
        <Select
          placeholder="Seleziona Autista..."
          options={autisti}
          onChange={(selectedOption) => {
            handleChange("autista", selectedOption);
          }}
        />
        <div className="dropdownHeader">Targa:</div>
        <Select
          placeholder="Seleziona Targa..."
          options={targhe}
          onChange={(selectedOption) => handleChange("targa", selectedOption)}
        />
        <div className="dropdownHeader">Tipo di report:</div>
        <div className="buttonsType">
          <div
            style={{
              borderRadius: "50px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: "20px 50px",
              flexWrap: "wrap",
              backgroundColor: type == 0 ? "#ce8c94" : "#FFFFFF",
              cursor: "pointer",
            }}
            onClick={() => {
              handleChange("type", 0);
              setType(0);
            }}
          >
            Multa
          </div>
          <div
            style={{
              borderRadius: "50px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: "20px 50px",
              flexWrap: "wrap",
              backgroundColor: type == 1 ? "#86b1bb" : "#FFFFFF",
              cursor: "pointer",
            }}
            onClick={() => {
              handleChange("type", 1);
              setType(1);
            }}
          >
            Sinistro
          </div>
        </div>
        <div className="dropdownHeader">Commento:</div>
        <div
          className="buttonCaricaFile"
          onClick={() =>
            setIsModalCaricaAllegatoOpen(!isModalCaricaAllegatoOpen)
          }
        >
          Carica un documento
        </div>
        {fileStored && <FilePreview fileStored={fileStored}></FilePreview>}

        <textarea
          type="text"
          className="commento"
          onChange={(e) => handleChange("commento", e.target.value)}
          content={report.commento}
          placeholder="Scrivi qui..."
        ></textarea>
        <div
          className={buttonDisabled ? "disabilitato" : "carica"}
          // href="mailto:sant22619@gmail.com"
          // href={`mailto:a.dimatteo@storacegroup.com?subject=${encodeURIComponent(
          //   type == 0 ? "Nuova Multa" : "Nuovo Sinistro"
          // )}&cc=a.storace@storacegroup.com;storace15@gmail.com;info@storacegroup.com;a.borzacchiello@storacegroup.com&body=${encodeURIComponent(
          //   "Veicolo: " +
          //     report.targa +
          //     " Autista: " +
          //     report.autista +
          //     " " +
          //     report.commento
          // )}`}
          onClick={buttonDisabled ? null : () => handleReport()}
        >
          Carica
        </div>
      </div>
      {loader ? <Loader /> : null}
    </div>
  );
}
