import React, { useState } from "react";
import "./modalStepper.css";
import api from "../../core/api";

import DropZoneModal from "../../components/modal/DropZoneModal";

import FilePreview from "../../components/filePreview/FilePreview";

import allegati from "../../images/svg/allegati.svg";
import close from "../../images/svg/close.svg";

export default function ModalStepper({
  stato,
  id,
  setLoader,
  autista,
  targa,
  setIsModalStepperOpen,
  commento,
}) {
  const [newCommento, setNewCommento] = useState("");
  const [fileStored, setFileStored] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isModalCaricaAllegatoOpen, setIsModalCaricaAllegatoOpen] =
    useState(false);
  // const setNextStatus = (stato) => {
  //   api.put("/appendText", { id, newCommento });
  //   stato == 3
  //     ? console.log(stato)
  //     : api.post("/setNextStep", { id, stato }).then((res) => {
  //         if (res.status === 200) {
  //           setLoader(true);
  //           window.location.reload();
  //         } else {
  //           setLoader(true);
  //           alert("Errore nel caricamento del report");
  //         }
  //       });
  // };

  const handleAddComment = () => {
    setLoader(true);
    setButtonDisabled(true);
    api
      .post("/add-comment", {
        id,
        commento: newCommento,
        fileStored,
        nome: localStorage.getItem("glsUtente"),
      })
      .then((res) => {
        setLoader(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoader(false);
        alert("Errore nel caricamento del report");
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  return (
    <div className="modal" onClick={() => setIsModalStepperOpen(false)}>
      <div className="innerModal" onClick={(e) => e.stopPropagation()}>
        {isModalCaricaAllegatoOpen && (
          <DropZoneModal
            setFileStored={setFileStored}
            setIsModalCaricaAllegatoOpen={() => setIsModalCaricaAllegatoOpen()}
          ></DropZoneModal>
        )}
        <div className="headerModal">
          <div className="titleModal">{autista}</div>
          <div className="targaModal">{targa}</div>
        </div>
        <div className="exit" onClick={() => setIsModalStepperOpen(false)}>
          <img src={close} alt="chiudi" />
        </div>
        <div className="commento">
          <div
            className="exit"
            onClick={() =>
              setIsModalCaricaAllegatoOpen(!isModalCaricaAllegatoOpen)
            }
          >
            <img src={allegati} alt="Aggiungi allegati" />
          </div>

          <div className="chat">{commento}</div>
          <br />
          <br />

          <textarea
            type="text"
            className="commentoInput"
            onChange={(e) => setNewCommento(e.target.value)}
            placeholder="Scrivi qui..."
            value={newCommento}
          ></textarea>
          {fileStored && <FilePreview fileStored={fileStored}></FilePreview>}
        </div>

        <div className="annulla" onClick={() => setIsModalStepperOpen(false)}>
          Annulla
        </div>

        <div
          // href={`mailto:a.dimatteo@storacegroup.com?subject=${encodeURIComponent(
          //   "Aggiornamento caso " + (autista ? autista : targa)
          // )}&cc=a.storace@storacegroup.com;storace15@gmail.com;info@storacegroup.com;a.borzacchiello@storacegroup.com&body=${encodeURIComponent(
          //   "Veicolo: " + targa + " Autista: " + autista + " " + newCommento
          // )}`}
          className={buttonDisabled ? "disabilitato" : "confirm"}
          onClick={buttonDisabled ? null : () => handleAddComment()}
        >
          Conferma
        </div>
        <div></div>
        {/* <div
          className="confirm"
          onClick={() => setNextStatus(stato == 3 ? 2 : stato + 1)}
        >
          Conferma
        </div> */}
      </div>
    </div>
  );
}
