import React, { useRef } from "react";

import jpgFile from "../../images/svg/jpgFile.svg";
import goBack from "../../images/svg/goBack.svg";

function DropZoneModal({ setFileStored, setIsModalCaricaAllegatoOpen }) {
  const fileInputRef = useRef(null);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const uploadFile = (file) => {
    if (!file) {
      // No file was selected
      return;
    }

    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    if (!validTypes.includes(file.type)) {
      alert(
        "Formato file non valido. Per favore seleziona un file JPEG, JPG, PNG o PDF"
      );
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      // Create an object containing both the file data URL and the file name
      const fileData = {
        url: reader.result,
        name: file.name,
      };

      // Update the parent component's state with this object
      setFileStored(fileData);
    };
    reader.readAsDataURL(file);

    setIsModalCaricaAllegatoOpen(false);
  };
  const handleFileChange = (e) => {
    uploadFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    uploadFile(e.dataTransfer.files[0]);
  };

  return (
    <div className="modal" onClick={() => setIsModalCaricaAllegatoOpen(false)}>
      <div className="innerModal" onClick={stopPropagation}>
        <div
          className="headerModal"
          style={{ cursor: "pointer" }}
          onClick={() => setIsModalCaricaAllegatoOpen(false)}
        >
          <img src={goBack} alt="Torna indietro" draggable="false" />
          <h3>Torna indietro</h3>
        </div>
        <div
          onClick={handleFileInputClick}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          className="dropZone"
        >
          <input
            accept="image/jpeg, image/jpg, image/png, application/pdf"
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <img src={jpgFile} alt="Scegli file" />
          <h4>Sposta un file qui o clicca per caricarlo</h4>
        </div>
      </div>
    </div>
  );
}

export default DropZoneModal;
