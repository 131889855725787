import { Navigate } from "react-router-dom";

import React from "react";

export default function PrivateRoute({ component: RouteComponent }) {
  const token = localStorage.getItem("glsToken");
  if (!token) {
    return <Navigate to={"/Login"} />;
  }
  return <RouteComponent />;
}
