import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";

import Login from "./pages/Login/Login";

import Report from "./pages/Report/Report";
import Cronologia from "./pages/Cronologia/Cronologia";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route
          path="/"
          element={<ProtectedRoute component={() => <Report />} />}
        />
        <Route
          path="/Cronologia"
          element={<ProtectedRoute component={() => <Cronologia />} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
