import React, { useEffect, useState } from "react";
import api from "../../core/api";

import arrowBack from "../../images/svg/arrowBack.svg";
import arrowNext from "../../images/svg/arrowNext.svg";
import close from "../../images/svg/close.svg";

import { Image } from "cloudinary-react";

export default function ModalAllegati({ id, setIsModalAllegatiOpen }) {
  const [images, setImages] = useState([]); // Assume you'll fill this state with your images
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [templateText, setTemplateText] = useState(
    "Sto caricando le immagini..."
  );

  useEffect(() => {
    api
      .post("/getImages", { id })
      .then((res) => {
        const optimizedImageUrls = res.data.optimizedImageUrls; // Adjust according to your API response
        setImages(optimizedImageUrls);
        setTemplateText("");
        if (optimizedImageUrls.length === 0) {
          setTemplateText("Nessun allegato presente");
        }
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, [id]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="modal" onClick={() => setIsModalAllegatiOpen(0)}>
      <div className="innerModalAllegati" onClick={(e) => e.stopPropagation()}>
        <div className="exit" onClick={() => setIsModalAllegatiOpen(0)}>
          <img src={close} alt="chiudi" />
        </div>
        <div className="arrowBack" onClick={prevImage}>
          <img src={arrowBack} alt="Foto precendete" />
        </div>
        {images.length > 0 ? (
          <Image
            className="responsiveImage"
            alt="Allegato"
            cloudName="dscgo39mj"
            publicId={images[currentImageIndex]}
          />
        ) : (
          <p>{templateText}</p> // or any other placeholder content
        )}

        {/* <img src={images[currentImageIndex]} alt="Allegato"></img> */}
        <div className="arrowNext" onClick={nextImage}>
          <img src={arrowNext} alt="Foto successiva" />
        </div>
      </div>
    </div>
  );
}
