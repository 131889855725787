import React, { useState } from "react";

import "./login.css";
import { useNavigate } from "react-router-dom";
import api from "../../core/api";
import Loader from "../../components/loader/Loader";

import eyeShow from "../../images/svg/eyeShow.svg";
import eyeHide from "../../images/svg/eyeHide.svg";

export default function Login() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (property, selectedOption) => {
    // Update the 'autista' property in the 'report' state
    setLoginData((prevReport) => ({
      ...prevReport,
      [property]: selectedOption.value ? selectedOption.value : selectedOption,
    }));
    console.log(loginData);
  };
  const handleLogin = () => {
    setLoader(true);

    api
      .post("/login", loginData)
      .then((res) => {
        localStorage.setItem("glsToken", res.data.token);
        localStorage.setItem("glsUtente", res.data.nome);
        navigate("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setError("Username o password errati");
      });
  };

  return (
    <div className="center-center">
      {loader ? <Loader /> : null}
      <h1>Effettua l'accesso</h1>
      <div className="inputButton">
        <input
          type="text"
          name="username"
          id="username"
          placeholder="Username"
          onChange={(e) => handleChange("username", e.target.value)}
        />
      </div>
      <div className="inputButton">
        <input
          type={showPassword ? "text" : "password"}
          name="password"
          id="password"
          placeholder="Password"
          onChange={(e) => handleChange("password", e.target.value)}
        />
        <img
          src={showPassword ? eyeHide : eyeShow}
          alt="Show Password"
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      <div>{error}</div>
      <div className="mainButton" onClick={handleLogin}>
        Accedi
      </div>
    </div>
  );
}
